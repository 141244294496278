<template>
  <v-container fluid>
    
    <v-card-actions>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar :to="`/grupos`" />
      </v-col>
    </v-row>
     </v-card-actions> 
    <grupos-campos
      :carregando-salvar="carregandoSalvar"
      :validacao-formulario="validacaoFormulario"
      :grupo="grupo"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import grupos from "@/api/grupos";

export default {
  components: {
    GruposCampos: () => import("./GruposCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
     
      validacaoFormulario: {},
      grupo: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await grupos.buscar(this.$route.params.id);
        var grupo = {
          nome: resposta.data.data.nome,
        };
        this.grupo = grupo;
      } catch (e) {
        this.$router.push(`/grupos`, () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(grupo) {
      this.carregandoSalvar = true;

      try {
        await grupos.atualizar(this.$route.params.id, grupo);
        this.$router.push(`/grupos`, () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
